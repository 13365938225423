import React, { useRef } from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Img from 'gatsby-image'

import Slider from 'react-slick'
import '../dependencies/slick/slick.css'
import '../dependencies/slick/slick-theme.css'

import { HomeQuery } from '../../graphql-types'
import { Content, ImgWithEffect } from '../components/styled'
import styled from 'styled-components'
import { DEFAULT_SIZE, BLACK, MOBILE_BREAKPOINT, MOBILE_SIZE } from '../Utils'
import { ChevronLeft, ChevronRight } from '../components/Icons'
import useWindowSize from '../hooks/useWindowSize'

const RootIndex = ({
  data,
  location,
}: {
  data: HomeQuery
  location: Location
}) => {
  const siteTitle = get(data, 'site.siteMetadata.title')
  const sliderRef = useRef(null)
  const [width] = useWindowSize()

  const landingNode = data.allContentfulLandingPage.edges[0].node

  const settings = {
    draggable: false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    swipeToSlide: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Layout location={location}>
      <Styled>
        <Content>
          <div></div>
          <div>
            <h1>{landingNode.intro.intro}</h1>
            <h1>
              <b>{landingNode.tagline.tagline}</b>
            </h1>
          </div>
        </Content>
        {typeof (window as any) !== 'undefined' && (
          <>
            <div
              className="carousel-wrapper"
              style={{ display: width < MOBILE_BREAKPOINT ? 'none' : 'flex' }}
            >
              <div
                onClick={() => sliderRef.current.slickPrev()}
                className="arrow left"
              >
                <ChevronLeft />
              </div>
              <div
                onClick={() => sliderRef.current.slickNext()}
                className="arrow right"
              >
                <ChevronRight />
              </div>
              <CarouselStyles>
                <Slider ref={sliderRef} {...settings}>
                  {data.allContentfulFeaturedProject.edges
                    // .sort(
                    //   (a, b) =>
                    //   // Date.parse(b.node.date) - Date.parse(a.node.date)
                    //   )
                    .sort((a, b) => a.node.priority - b.node.priority)
                    .map(({ node }, i) => (
                      <div key={i} className="card-wrapper">
                        <Link to={node.slug}>
                          <div className="card">
                            <div className="img">
                              <ImgWithEffect fluid={node.featuredImage.fluid} />
                              {/* <Img fluid={node.featuredImage.fluid} /> */}
                            </div>
                            <p className="legend">{node.title}</p>
                          </div>
                        </Link>
                      </div>
                    ))}
                </Slider>
              </CarouselStyles>
            </div>

            <div
              style={{ display: width > MOBILE_BREAKPOINT ? 'none' : 'block' }}
            >
              {console.log(data.allContentfulFeaturedProject.edges)}
              {data.allContentfulFeaturedProject.edges
                .sort((a, b) => a.node.priority - b.node.priority)
                .map(({ node }, i) => (
                  <div
                    key={i}
                    className="card-wrapper"
                    style={{ padding: '1rem' }}
                  >
                    <Link to={node.slug}>
                      <div className="card">
                        <Img fluid={node.featuredImage.fluid} />
                        <p className="legend">{node.title}</p>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          </>
        )}

        <Helmet title={siteTitle} />
        <br />
        <br />
        <br />
      </Styled>
    </Layout>
  )
}

const Styled = styled.div`
  h1 {
    text-align: justify;
    margin-top: 0;
    font-size: ${DEFAULT_SIZE * 1.2}rem;
    color: ${BLACK};
  }
  .logo {
    width: 16rem;
    padding-bottom: 2.8rem;
  }

  .legend {
    margin-top: 0.6rem;
  }

  .carousel-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;

    .arrow {
      position: absolute;
      display: flex;
      align-items: center;
      width: 4rem;
      height: 100%;
      padding: 0.2rem;
      z-index: 10;
      cursor: pointer;
    }

    .left {
      left: 0;
    }

    .right {
      right: 0;
      justify-content: flex-end;
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    h1 {
      font-size: ${MOBILE_SIZE}rem;
      text-align: left;
      line-height: 1.4;
    }

    .carousel-wrapper {
      .arrow {
        padding: 0.2rem;
        width: 2rem;
      }
    }
  }
`

export default RootIndex

const CarouselStyles = styled.div`
  /* padding: 4rem 1rem 0rem 1rem; */
  margin-top: 4rem;
  width: calc(100% - 2rem);

  .slick-list {
    overflow: initial;
  }

  .slick-dots {
    top: -4rem;
    height: 2rem;
    text-align: left;
    margin-left: calc(33.333% + 1rem);

    li {
      margin-left: 0;

      button {
        padding-left: 0;

        &::before {
          text-align: left;
        }
      }
    }
  }

  .slick-dots li button::before {
    font-size: 10px;
  }

  .card-wrapper {
    padding: 0 1rem;
    /* padding-left: 2rem; */
    width: 100%;
    overflow: hidden;
  }

  .carousel .slide {
    background-color: transparent;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: calc(100%);

    .slick-dots {
      margin-left: 0;
      display: flex !important;
      justify-content: center;
      align-items: center;
    }

    .card-wrapper {
      /* padding: 0 2rem 0 1rem; */
      padding-right: 1rem;
    }
  }
`

export const pageQuery = graphql`
  query Home {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulFeaturedProject {
      edges {
        node {
          title
          slug
          date
          priority
          featuredImage {
            fluid(maxWidth: 800, background: "rgb:000000") {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulLandingPage {
      edges {
        node {
          id
          intro {
            intro
          }
          tagline {
            tagline
          }
        }
      }
    }
  }
`
